'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import Routes from 'src/constants/Routes';
import Error from 'src/views/Error';

type ErrorPageProps = {
    error: Error & { digest?: string };
    reset: () => void;
};

function ErrorPage({ error }: ErrorPageProps) {
    useEffect(() => {
        datadogRum.addError(error, {
            stack: error.stack,
            digest: error.digest,
        });
    }, [error]);

    return (
        <Error
            type="unknown"
            title={
                <>
                    There was an <mark>error</mark>
                </>
            }
            message={
                <>
                    Contact us on&nbsp;
                    <a href="mailto:hello@getmosh.com">hello@getmosh.com</a> if
                    you need further support.
                </>
            }
            ctaText="Back to home"
            ctaUrl={Routes.HOME}
        />
    );
}

export default ErrorPage;
