'use client';

import HTMLReactParser from 'html-react-parser';
import { useSearchParams } from 'next/navigation';
import { Button } from '@get-mosh/msh-shared';
import LayoutBasic from 'src/components/Layout/Basic';
import styles from './error.module.scss';
import { useTrackPage } from 'src/analytics/Events';
import { PageType } from 'src/analytics/EventProps';

type ErrorProps = {
    type: string;
    title: string | JSX.Element;
    message: string | JSX.Element;
    ctaText: string;
    ctaUrl: string;
};

const Error = ({ type, title, message, ctaText, ctaUrl }: ErrorProps) => {
    const searchParams = useSearchParams();

    const errorTitle = (searchParams.get('error_title') as string) ?? title;
    const errorMessage = searchParams.get('error_message' as string) ?? message;
    const errorType = (searchParams.get('error_type') as string) ?? type;
    const errorCtaText =
        (searchParams.get('cta_text' as string) as string) ?? ctaText;
    const errorCtaUrl =
        (searchParams.get('cta_url' as string) as string) ?? ctaUrl;

    useTrackPage('Error', {
        page_type: PageType.ERROR,
        title: errorTitle,
        error_type: errorType,
        error_message: errorMessage.toString(),
    });

    return (
        <LayoutBasic className={styles['error']}>
            <div className={styles['error--message']}>
                <h4>Sorry!</h4>
                <h1>{errorTitle}</h1>
                <p>
                    {typeof errorMessage === 'string'
                        ? HTMLReactParser(errorMessage)
                        : errorMessage}
                </p>
            </div>
            <Button
                className={styles['error--button']}
                text={errorCtaText}
                as="a"
                href={errorCtaUrl}
            />
        </LayoutBasic>
    );
};

export default Error;
